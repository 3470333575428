@media (max-width: 1536px) {
	.c-page-process {
		.left-panel {
			width: 35%;
		}

		.right-panel {
			width: 65%;
		}
	}

	.container-chart-on-map {
		height: 250px;
	}

	/* Thêm các quy tắc CSS ở đây */
	.sidebar {
		height: 77vh !important;
		overflow: auto;
	}

	.navigation-c {
		height: 66px !important;

		.navbar-logo {
			line-height: 100% !important;

			img {
				height: 47px !important;
			}

		}
	}

	h2 {
		font-size: 1.4rem !important;
	}

	h5 {
		font-size: 1rem !important;
	}


	.font-size-36 {
		font-size: 32px !important;
	}

	.font-size-34 {
		font-size: 30px !important;
	}

	.font-size-32 {
		font-size: 28px !important;
	}

	.font-size-30 {
		font-size: 26px !important;
	}

	.font-size-28 {
		font-size: 24px !important;
	}

	.font-size-26 {
		font-size: 22px !important;
	}

	.font-size-24 {
		font-size: 20px !important;
	}

	.font-size-22 {
		font-size: 18px !important;
	}

	.font-size-20 {
		font-size: 16px !important;
	}

	.font-size-18 {
		font-size: 14px !important;
	}

	.font-size-16 {
		font-size: 12px !important;
	}

	.icon-top-right {
		padding-top: 20% !important;
	}

	.icon-top-right-badge {
		top: 16px !important;
	}

	.navbar-header {
		height: 50px !important;
	}

	.page-content {
		padding: calc(27px + 24px) 0 0 0 !important;
	}

	.logo-lg {
		img {
			// width: 9rem !important;
		}
	}

	.header-item {
		height: 50px !important;
	}

	#layout-report {
		height: calc(100vh - 51px) !important;

		.editor-left-sidepanel {
			width: 280px;
		}

		.sidepanel-toggle {
			right: -15px !important;
			width: 16px !important;
			height: 50px !important;
		}

		.editor-left-sidepanel-nav {
			width: 55px !important;


		}

		.container-chart {
			.c-type-chart {
				width: 5.2rem !important;
				height: 4.8rem !important;
			}
		}

		.editor-header {
			height: 45px !important;
		}

		.c-container-page {
			.header {
				height: 45px !important;
			}

			.page-active {
				width: 75vw !important;
				max-width: 75vw !important;
			}
		}

		.mt-5 {
			margin-top: 2rem !important;
		}
	}

	.page-container {
		padding: 55px 18px 0px 18px !important;
	}

	.c-screen-config-table {
		height: 87vh !important;

		table {
			thead {
				tr {
					position: sticky;
					top: 0;
					// th{
					// 	padding-top: 1%;
					// 	padding-bottom: 1%;
					// }
				}
			}
		}
	}

	.c-page-content {
		height: calc(100vh - 55px) !important;
	}

}

@media only screen and (max-width: 1000px) {
	.nice-cirle-img2 {
		width: 68px !important;
		margin-top: 0px !important;
	}

	.nice-cirle {
		width: 105px !important;
		height: 105px !important;
	}
}

.nice-cirle-img2 {
	width: 78px;
	margin-top: 5px;
}

@media (min-width: 1900px) {
	.c-page-process {
		.left-panel {
			width: 25%;
		}

		.right-panel {
			width: 75%;
		}
	}
}

@media (min-width: 1920px) {
	.c-page-process {
		.left-panel {
			width: 25%;
		}

		.right-panel {
			width: 75%;
		}
	}

	.container-chart-on-map {
		height: 300px;
	}

	.vertical-menu {
		top: 70px !important;
	}

	.editor-left-sidepanel {
		width: 360px;
	}

	.nice-cirle-img2 {
		width: 78px !important;
		margin-top: 5px;
	}

	.c-screen-config-table {
		height: 87vh !important;
	}

	/* Thêm các quy tắc CSS ở đây */
	.sidebar {
		overflow: auto;
		height: 80vh !important;
	}

	.font-size-28 {
		font-size: 28px !important;
	}

	.font-size-30 {
		font-size: 30px !important;
	}
}