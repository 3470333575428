// Lightbox
@import "~ngx-lightbox/lightbox.css";

// Select 2
@import "~@ng-select/ng-select/themes/default.theme.css";

// Date Picker
@import "~flatpickr/dist/flatpickr.css";

// LeafLet Chart
@import "leaflet/dist/leaflet.css";

// Emoji Picker
@import "~@ctrl/ngx-emoji-mart/picker";

body {
  // font-family: Montserrat,sans-serif !important;
  // font-family: 'Roboto', sans-serif !important;
  font-family: "Be Vietnam Pro", sans-serif !important;
  // background-image: url('../images/bg_main.jpg');
  background-repeat: repeat;
  background-position: 0 0;
}

body,
.content-scroll {
  overflow-y: auto;
  overflow-x: auto;
}

.content-scroll::-webkit-scrollbar-thumb,
.mat-autocomplete-panel::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
.mat-select-panel::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #aaaaaa6e;
}

.content-scroll::-webkit-scrollbar,
.mat-autocomplete-panel::-webkit-scrollbar,
body::-webkit-scrollbar,
.mat-select-panel::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.emoji-mart {
  position: absolute;
  bottom: 20px;
  left: -80px;
}

.emoji-mart-preview {
  display: none;
}

// dropdown
.dropdown-toggle {
  &:after {
    display: none;
  }
}

.modal-backdrop {
  // z-index: 1002 !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropzone .dz-message {
  font-size: 24px;
  min-height: 150px !important;
  border: none !important;
  background: #fff !important;
  color: #495057 !important;
}

// ----- Advance Ui --------//
// Rating
.stars {
  font-size: 30px;
  color: var(--bs-gray-600);
}

.star {
  position: relative;
  display: inline-block;
}

.star_fill {
  color: $gray-500;
}

.full {
  color: #b0c4de;
}

.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: var(--bs-gray-600);
}

.filled {
  color: #1e90ff;
}

.bad {
  color: #deb0b0;
}

.filled.bad {
  color: #ff1e1e;
}

.halfHeart {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: red;
}

// Calendar
.flatpickr-calendar.open {
  z-index: 1061;
}

// Swich Btn
.switch.switch-small.checked>.switch-pane>span {
  color: #fff;
}

.switch.switch-small>.switch-pane>span {
  font-size: 14px;
}

// Ck Editer
.ck-editor__editable {
  min-height: 245px !important;
}

.custom-accordion {
  .accordion-list {
    padding: 0px;
    background-color: transparent;
  }
}

table {
  td {
    min-width: 80px;
  }
}

// my custom

.c-card-header {
  background: #cfeffe;
  border-radius: 20px 20px 0px 0px !important;
  color: #1d9ad6;
}

.border-radius-card {
  border-radius: 20px 20px 0 0;
}

.c-accordion {
  padding: 3% 5%;
  background: #cfeffe;
  color: #1d9ad6;
  font-weight: 500;
}

.fw-bold {
  font-weight: bold;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500 !important;
}

.ol-uncollapsible {
  display: none;
}

.color-main {
  color: #1d9ad6;
}

.color-text {
  color: #235a81;
}

.color-grey-400 {
  color: #8c8ca1;
}

.c-avatar-xs {
  height: 60px !important;
  width: 60px !important;
}

.color-link {
  color: #556ee6 !important;
}

.deliver-horizontal {
  border: 1px solid #d2d2d273;
}

// style color

.bg-main {
  background-color: green;
}

.bg-item-menu {
  background-color: #4cafdf;
}

.bg-title-main {
  background-color: $green;
}

.bg-none {
  background: none;
}

.bg-eee {
  background-color: #eee;
}

.bg-lemonchiffon {
  background-color: lemonchiffon !important;
}

.bg-readonly {
  cursor: not-allowed;
  background-image: url("../images/layouts/bg_readonly.png");
  background-color: #eee !important;
}

.bg-blue-1 {
  background-color: #e9f4fd;
}

.bg-th {
  background: linear-gradient(to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 246, 246, 1) 47%,
      rgba(237, 237, 237, 1) 100%);
}

.bg-dark-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: #25232352;
}

.form-search {
  .c-form-group {
    .h-icon-input {
      position: absolute;
      left: 3%;
      top: 13%;
      padding: 1.1%;
      background: #e9e8e8;
      border-radius: 5px;
      align-self: center;
      vertical-align: middle;
      z-index: 1;
    }
  }
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-65 {
  width: 60% !important;
}

.w-60 {
  width: 60% !important;
}

.w-40 {
  width: 40% !important;
}

.w-35 {
  width: 35% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-15 {
  width: 15% !important;
}

.w-10 {
  width: 10% !important;
}

.w-5 {
  width: 5% !important;
}

.w-45-px {
  width: 45px !important;
  min-width: 45px !important;
}

.h-35px {
  height: 35px !important;
}

.min-h-60-vh {
  height: 60vh;
}

.mh-60-vh {
  max-height: 60vh;
}

.mh-70-vh {
  max-height: 70vh;
}

.h-40 {
  height: 40%;
}

.c-accordion-select {
  .accordion-body {
    padding: 0;

    .c-accordion-item {
      border-bottom: 1px solid #d2d2d273;
      padding: 3%;
    }
  }
}

.c-map-page {
  height: 96vh;
}

.ol-unselectable.ol-control {
  display: none;
}

.form-check-input[type="checkbox"] {
  border: 1px solid #ccc;
}

.ol-tooltip {
  font-weight: bold !important;
}

.mw-100 {
  min-width: 100%;
}

.c-accordion {
  .accordion-item {
    position: relative;

    .accordion-button {
      padding-top: 1%;
      padding-bottom: 1%;
    }

    // border-top: none;
    &:first-child {
      border: none;
    }

    border-left: none;
    border-right: none;
    border-bottom: none;

    &:not(:first-of-type) {
      border-top: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
    }
  }

  // .accordion-button {
  // 		color: var(--bs-accordion-active-color) !important;
  // 		background-color: var(--bs-accordion-active-bg) !important;
  // 		box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--bs-accordion-border-color) !important;
  // }

  .accordion-button {
    &:not(.collapsed) {
      background-color: white !important;

      &::after {
        display: none;
      }
    }

    &::after {
      display: none;
    }
  }

  .accordion-body {
    padding: 1% 0 1% 10%;
  }
}

// border
.c-border-bottom {
  border-bottom: 1px solid #cccccc85;
}

.c-border-right {
  border-right: 1px solid #cccccc85;
}

.c-border-left {
  border-left: 1px solid #cccccc85;
}

.border-none {
  border: none;
}

.c-card-header {
  background-color: #eef1fd !important;
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border: 1px solid rgba(204, 204, 204, 0.5215686275) !important;
  border-bottom: none;
}

.c-card-none-boxshadow {
  border: 1px solid #ced4da !important;
  box-shadow: unset !important;
}

.c-accordion-child {
  .accordion-item {
    border-bottom: none !important;
  }

  .accordion-body {
    border-bottom: none;
  }
}

.navigation.nav-sticky .navbar-nav .nav-item .nav-link {
  color: #fff9;
}

.navigation.nav-sticky .navbar-nav .nav-item .nav-link:hover,
.navigation.nav-sticky .navbar-nav .nav-item .nav-link.active {
  color: white;
}

.border-left-0 {
  border-left: 0;
}

.c-tree-node {
  display: inherit !important;
}

.c-mat-tree {
  .mat-tree-node {
    min-height: 30px;

    .mat-icon-button {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }

  mat-tree-node {
    // padding-left: 20px !important;
  }

  mat-checkbox {
    label {
      margin-bottom: 0;
    }
  }

  .c-mat-checkbox {
    .mat-checkbox-layout {
      margin-bottom: 0;

      .mat-checkbox-frame {
        border: 1px solid orange;
      }
    }
  }

  .mat-checkbox-layout {
    font-weight: 400;
  }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #556ee6;
}

.cursor-pointer {
  cursor: pointer;

  &:hover {
    color: orange !important;
  }
}

.cursor-grab {
  cursor: grab;
}

.d-dropdown-left {
  // left: 100% !important;
  --bs-position: end;
  top: 0;
}

.d-dropdown-left.show {
  top: 0% !important;
}

.c-paginator {
  .c-icon {
    width: 20px;
    height: 20px;
    border: 1px solid #bebebe;
    border-radius: 50%;
    text-align: center;
    /* vertical-align: middle; */
    align-self: center;
    display: table;
    padding: 3px;
    color: #bebebe;

    i {
      align-items: center;
      text-align: center;
      vertical-align: middle;
      align-self: center;
      display: table-cell;
    }
  }

  .c-input {
    width: 65px;
    display: flex;
    border: 1px solid #bebebe;
    border-radius: 3px;
    padding: 1% 7%;

    input {
      width: 100%;
      text-align: end;
      color: #bebebe;
      border: none;

      &:focus-visible {
        outline: none !important;
      }
    }

    span {
      color: #bebebe;
      vertical-align: middle;
      align-self: center;
    }
  }
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 350px;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "✖";
}

.c-input-select-group {
  .input-group-text {
    position: absolute;
    height: 70%;
  }

  .mat-form-field-hide-placeholder {
    padding-left: 10.5%;

    .mat-form-field-wrapper {
      .mat-form-field-flex {
        border: 1px solid var(--bs-input-border-color);
        border-radius: 0 0.25rem 0.25rem 0;
        border-left: none;

        .mat-form-field-infix {
          padding-top: 1%;
          padding-left: 3.8%;

          &:focus-visible {
            outline: none;
          }
        }
      }
    }
  }
}

.contextmenu {
  position: absolute;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.18);
  background: #fff;
  padding: 3%;
  z-index: 1056;
}

.font-none {
  font-family: none;
}

.c-acc-item-active {
  background: #cccccc75;
}

.c-acc-item {
  &:hover {
    background: #cccccc40;
  }
}

.scroll-vertical {
  // max-height: 70vh;
  // overflow: auto;
}

.c-accordion-fixed-button {
  .accordion-item {
    color: #6c757d !important;

    .accordion-button {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .accordion-body {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

// modal css
.modal-content {
  box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
}

.jsoneditor-poweredBy {
  display: none;
}

.modal-xxl {
  --bs-modal-width: 80%;
}

// form
.autocomplete-container {
  box-shadow: none !important;

  input {
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
  }
}

snack-bar-container {
  margin-top: 20% !important;
}

// .cdk-overlay-pane{}
.label-mb-0 {
  label {
    margin-bottom: 0 !important;
  }
}

.mat-menu-panel {
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
}

// tree view
.node-active {
  background-color: #eee;
}

.node-hover {
  &:hover {
    background-color: #eee;
  }
}

.text-grey {
  color: #808080a8;
}

.text-orange {
  color: orange;
}

.navbar-header-bg {
  background: linear-gradient(#fff, #dcdcdc);

  .item-menu {
    background: linear-gradient(#fff, #dcdcdc);
    border-right: 1px solid #fff;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    cursor: pointer;

    &:hover {
      background: #dcdcdc;
    }

    &.active {
      background: #dcdcdc;
    }
  }

  .first-item-menu {
    border-left: none;
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.c-checklist-leaf {
  .mat-checkbox-layout {
    // width: 80%;
  }
}

.h-100-vh {
  height: 100vh !important;
}

.h-90-vh {
  height: 90vh;
}

.h-40-vh {
  height: 40vh !important;
}

.custom-mouse-position {
  pointer-events: auto;
  position: absolute;
  bottom: 1%;
  left: 1%;
  font-size: 16px;
  color: darkslategrey;
}

.image-card {
  height: 20vh;
  object-fit: cover;
}

.image-card-5 {
  height: 5vh;
  object-fit: cover;
}

.image-card-w-100 {
  width: 100%;
  object-fit: cover;
}

.image-crop {
  object-fit: cover !important;
}

.vh-100 {
  height: 100vh;
}

.c-ng-select {
  .ng-select-container {
    background: #ffffff !important;
    border: none;
  }
}

// menu
.mat-menu-item {
  line-height: 35px !important;
  height: 35px !important;
  user-select: none;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
}

.menu-opacity {
  width: 250px;
}

.menu-opacity {
  width: 250px;
}

.c-matmenu-right {
  left: 100%;
  top: -20%;
}

.deliver-menu {
  width: 1px;
  background: #d9dfe1;
}

// end menu

.sticky-head {
  top: 0;
  position: sticky;
  background: #ffffff;
  z-index: 1;
}

.color-disable {
  color: #00000061;

  &:hover {
    color: orange !important;
  }
}

.c-wait {
  color: #00000061 !important;

  a {
    color: #00000061 !important;

    i {
      color: #00000061 !important;
    }
  }
}

.c-content-tab-layer {
  .tab-pane {
    height: 100%;
  }
}

.italic-placeholder {
  &::placeholder {
    font-style: italic;
  }
}

.row-filter {
  background: #556ee694 !important;

  &:hover {
    background: #556ee694 !important;
  }
}

.row-filter>* {
  --bs-table-accent-bg: #556ee694 !important;
}

#mapkg {
  canvas {
    // width: 100% !important;
  }
}

.shadow-r {
  box-shadow: 0.125rem 0 1rem rgba(0, 0, 0, 0.1) !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

.c-border-danger {
  border: 1px solid #f46a6a !important;
  border-radius: 0.25rem;
}

.cdk-overlay-container {
  z-index: 1067;
}

.c-menu-dashboar-builder {
  left: 130%;
}

.c-tab-content-st {
  // height: 70vh;

  .tab-pane {
    height: 100%;

    .c-acc-setting-report {
      .accordion-item {
        border: none !important;
      }

      .accordion-button {
        padding-bottom: 0;
      }

      .accordion-button:not(.collapsed) {
        background: unset !important;
      }
    }
  }
}

.icon-spinner-inside-block {
  width: 50px !important;
  height: 50px !important;

  svg {
    height: 50px !important;
    width: 50px !important;
  }
}

.c-tab-setting {
  .nav-item {
    background: white;

    .nav-link.active {
      background: #eef9fa !important;
      border: 1px solid #eef9fa !important;
    }

    .nav-link {
      &::after {
        height: 0 !important;
      }
    }
  }
}

.c-menu-root {
  position: relative;

  .c-content-menu {
    display: none;
    position: absolute;
    left: 100%;
    background: white;
    top: 0;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.18);
  }
}

.table-sticky {
  table {
    thead {
      tr {
        position: sticky;
        top: 0;
        z-index: 9;
      }
    }
  }
}

.c-accordion-map-report {
  .accordion-item {
    .accordion-button {
      padding: 3%;
    }

    .accordion-body {
      padding: 3% 5%;
    }
  }
}

.navigation-c {
  // background-image: url('../../../assets/images/banner.png');
  // background-color: $green !important;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;

  // background-color: $green !important;
  background-color: #0a314d !important;
  height: 65px;
  // background-image: url("../images/layouts/footer.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  // opacity: 0.7;
  color: rgba($white, 0.5);
}

.c-fieldset {
  display: block;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1%;
}

.c-legend {
  float: left;
  margin-top: -2%;
  font-size: 18px;
  background: white;
  padding: 0 1%;

  label {
    margin-bottom: 0;
  }
}

.container-fluid {
  margin-top: 1%;
}

.c-menu-rightclick {
  left: 100%;
  top: -40%;
}

.border-ng-select-none {
  .ng-select-container {
    border: none;
  }
}

.mat-icon-button-sm {
  width: 30px;
  height: 25px !important;
  line-height: 25px !important;
}

.bjs-powered-by {
  display: none !important;
}

.c-tab-content-bpmn {
  .tab-pane {
    height: 100%;
  }
}

.resize-th {
  resize: horizontal;
  overflow: auto;
}

.mat-expansion-panel {
  box-shadow: unset !important;
}

.mat-expansion-panel-header.mat-expanded {
  height: 48px !important;
}

.c-json-editor {
  height: 70vh;
}

.c-json-editor> :first-child {
  height: 100%;
}

.c-legend-fs {
  margin-top: -5%;
  background: white;
  padding: 0 1%;
  align-self: center;
}

.my-fieldset {
  border: 1px solid #ced4da !important;
  border-radius: 4px;
  position: relative;
  padding: 20px;

  .my-legend-title {
    position: absolute;
    background: white;
    padding-right: 1%;
    /* padding-top: 1%; */
    left: -1px;
    top: -10px;
  }

  .my-legend-action {
    position: absolute;
    background: white;
    padding-left: 1%;
    padding-right: 1%;
    right: -1px;
    top: -15px;
  }
}

.c-page-process {
  height: calc(100vh - 77px);

  .c-tab-content-process {
    padding: 3%;
    height: 97%;
    display: flex;
    flex-direction: column;

    .tab-pane {
      height: 100%;

      .c-container-tab {
        height: 100%;

        .c-content-tab {
          flex: 1 1 auto;
        }

        .c-action-tab {
          padding: 10px;
          position: absolute;
          bottom: 0;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
}

.scoll-x {
  overflow-x: auto !important;
}

.step-number {
  padding: 4px 9px;
  border: 1px solid #bbc1c7;
  border-radius: 50%;
  color: #bbc1c7;
}

.step-text {
  color: #bbc1c7;
}

.c-step-active {
  .step-text {
    color: $primary;
    font-weight: 600;
  }

  .step-number {
    color: white;
    border: 1px solid $primary;
    background-color: $primary;
    font-weight: 600;
  }
}

.c-step-done {
  .step-text {
    color: $primary;
    font-weight: 600;
  }

  .step-number {
    color: $primary;
    font-weight: 600;
    border: 1px solid $primary;
  }
}

.btn-border {
  border: 1px solid #ced4da !important;
}

.c-view-location {
  position: absolute;
  z-index: 9;
  left: 1%;
  bottom: 1%;
  line-height: 1.3;
  font-size: 12px;
  background: rgb(255 255 255 / 36%);
  padding: 5px;
}

.mat-accent .mat-slider-track-fill {
  background-color: #179568 !important;
  height: 5px;
}

.mat-slider-thumb-label {
  background-color: #179568 !important;
}

.mat-slider-thumb {
  background-color: #179568 !important;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 42.4%, 0.3);
  border-radius: 4px;
  border: 3px solid rgba(244, 245, 247, 0.1);
}

::-webkit-scrollbar-track {
  background-color: hsla(0, 0%, 42.4%, 0.1);
  border-radius: 4px;
}