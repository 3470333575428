//@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
//@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
//@import '@ali-hm/angular-tree-component/css/angular-tree-component.css';


// html,
// body {
//   height: 100%;
// }

// body {
//   margin: 0;
//   font-family: Roboto, "Helvetica Neue", sans-serif;
// }

//header


.main-header .logo {
  background-color: #2A3F54 !important;
}


// menu
.vertical-menu {
  width: 250px;
  // z-index: 1001;
  background: #ffffff;

  bottom: 0;
  // margin-top: 0;
  // position: fixed;
  // top: 70px;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.sidebar-menu .treeview-menu {
  background-color: white;
}



.sidebar-menu .treeview-menu:before {
  left: 10px !important;
}

.sidebar-menu .treeview-menu {
  padding-left: 10px !important;
}

.sidebar-menu,
.main-sidebar .user-panel,
.sidebar-menu>li.header {
  white-space: normal;
}

.vertical-menu ul li a {
  color: #545a6d !important;
}

.sidebar-menu>li:hover>a,
.sidebar-menu>li.active>a {
  color: #556ee6 !important;
  border-right: 3px solid #556ee6 !important;
  background: white !important
}

.sidebar-menu .treeview-menu>li>a {
  font-size: 14 !important;
}


.active-link {
  font-weight: bold !important;
  color: blue !important;
}


.drop_down .menus a {
  font-weight: 400 !important;
  color: #545a6d !important;
}

.dropdown-menu .font-weight-bold {
  font-weight: 400 !important;
}

.main-header .navbar-custom-menu,
.main-header .navbar-right {
  max-width: 400px;
}

.navbar-expand .navbar-collapse {
  display: contents;
}

.header-title {
  margin: auto;
  width: auto;
}

.header-title h1 {
  color: white;
  padding-top: 10px;

}




.table {
  color: #545a6d !important;
}

label {
  font-size: 14px;
  color: #545a6d !important;
}

.content-header h1 {
  color: #545a6d !important;
}

.content-header {
  padding: 20px 30px !important;
}

.inline-block {
  display: inline-block;
}

// button i {
//   font-size: 12px;
// }

.page-link {
  // padding: 5px 5px !important;
  // line-height: 12px !important;
}


.modal-dialog {
  max-width: 95%;
}


.width100 {
  width: 95%;
}

.toast-top-right {
  position: fixed;
  top: 70px;
  right: 5px;
  z-index: 9999;
}

/* pagination.component.css */

.pagination {
  display: flex;
  justify-content: right;
  padding-right: 20px;
}

.footer-talble .sum-table {
  display: inline-block;
}

.footer-talble .sum-table p {
  display: inline-block;
  padding-left: 5px;
}


.page-link {
  cursor: pointer;
  margin: 0 5px;
}

.page-link.active {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
}

// .modal {
//   z-index: 2000 !important;
// }

.md-content button {
  display: unset !important;
  margin: unset !important;
  font-size: 0.8em;
  margin-right: 10px !important;
}

.n-modal-footer {
  text-align: right;
}

.modal-footer {
  text-align: right;
}

.modal-footer button {
  margin-right: 10px;
}

.x-large {
  font-size: x-large;
}

.ng-autocomplete {
  width: 100%;
  display: table;
  margin: 0 auto;
}

mat-form-field {
  width: 100%;
}

.x-large {
  font-size: x-large;
}

.pointer {
  cursor: pointer;
}

.color-gray {
  color: gray;
}

// button i {
//   padding-right: 10px;
// }

table tr .center {
  text-align: center;
}


table tr .right {
  text-align: right;
}

.centered-div {
  background-color: #FBFBFB;
  text-align: center;
  padding-top: 50px;
}

.gen-html-form label {
  display: inline-block;
}

.gen-html-form p {
  display: inline-block;
  color: red;
  padding-left: 5px;
}

.ct-required input {
  border-color: red;
}

.ct-required select {
  border-color: red;
}

.ct-required textarea {
  border-color: red;
}

.ct-required textarea {
  border-color: red;
}


// button i {
//   font-size: smaller;
// }


.loading .ct-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

table tr {
  height: 10px;
}

table tr td {
  font-weight: 400;
  height: 10px;
}

table tr td {
  font-size: 16;
}

.table td,
.table th {
  vertical-align: middle;
}

table tr {
  cursor: pointer;
}

.active-node {
  background-color: #FBFBFB;
  color: #6A88FE;
  font-weight: bold;
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 2000 !important;

}


.tree,
.tree ul {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: white;
}

// .tree li {
//   margin: 0;
//   padding: 0 0 0 20px;
//   position: relative;
// }

// .tree li::before, .tree li::after {
//   content: '';
//   position: absolute;
//   left: -20px;
// }

.tree li::before {
  border-left: 1px solid #000;
  top: 0;
  bottom: 0;
}

.tree li::after {
  border-top: 1px solid #000;
  top: 10px;
  width: 20px;
}

.tree>li::before,
.tree>li::after {
  border: 0;
}

.tree li:last-child::before {
  height: 10px;
}

.tree li .fa-folder-open-o:before {
  content: "\f115";
}


// bao cao
.bc-don-vi {
  font-weight: bold;
}

.bc-thoi-diem{
  text-align: center;
}

.bc-tieu-de {
  text-align: center;
}

.bc-ngay-thang{
  text-align: right;
  padding-right: 150px !important;
}

.bc-nguoi-lap{
  
  padding-right: 188px !important;
    font-weight: bold;
    text-align: right;
}

.bc-ky-ho-ten{
  padding-right: 182px !important;
    text-align: right;
}

.container-loading1 {
  height: 100vh;
    position: absolute;
    width: 100%;
    z-index: 2000;    
  background-color: #3333331a;
  top: 0;
  left: 0;
  z-index: 9999;
}

.my-custom-modal{
  max-width: 95% !important;
}